body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: var(--zephr-color-background-main);
  font-family: var(--zephr-typography-body-font, -apple-system, BlinkMacSystemFont, 'Microsoft Sans Serif', 'Segoe UI', 'Roboto',
   'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 999px) {
  body.prevent-scroll {
    height: 100vh;
    overflow: hidden;
  }  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
